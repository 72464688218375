import React, { useState } from "react"
import { Text, View, TouchableOpacity, Linking } from "react-native"
import Colors from "../constants/Colors"
import Ionicons from "@expo/vector-icons/Ionicons"

export default function AppSuggest() {
  const [enable, setEnable] = useState(true)

  const _onClose = () => setEnable(false)
  const _onTransfer = () => Linking.openURL("https://app.aminh.pro")

  if (!enable) return null

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: Colors.error,
        borderRadius: 20,
        marginBottom: 10,
        justifyContent: "center",
        alignItems: "flex-end",
        padding: 20,
        gap: 13,
      }}
    >
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 8,
        }}
      >
        <Ionicons name="warning" size={24} color="#FFFFFF" />

        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontWeight: "bold",
            fontSize: 18,
            color: "#FFFFFF",
            textShadow:
              "-0.2px 0, 0 0.2px, 0.2px 0, 0 -0.2px, -0.2px -0.2px, 0.2px 0.2px, -0.2px 0.2px, 0.2px -0.2px",
          }}
        >
          پنل جدید از راه رسید!
        </Text>
      </View>

      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontWeight: "bold",
          fontSize: 12,
          color: "#FFFFFF",
          lineHeight: 30,
        }}
      >
        در پنل جدید، شما به راحتی می‌توانید از امکانات متنوع استفاده کنید و از ویژگی‌های جدید
        بهره‌مند شوید. این پنل به گونه‌ای طراحی شده است که تجربه کاربری شما را بهبود بخشد و دسترسی
        به ابزارها و خدمات مختلف را آسان‌تر کند. با استفاده از این امکانات، می‌توانید کارهای خود را
        سریع‌تر و با دقت بیشتری انجام دهید.
      </Text>

      <TouchableOpacity
        activeOpacity={0.7}
        onPress={_onTransfer}
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          width: "100%",
          backgroundColor: "#FFFFFF20",
          borderWidth: 2,
          borderColor: "#FFFFFF80",
          borderRadius: 15,
          marginTop: 10,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontWeight: "bold",
            fontSize: 12,
            color: "#FFFFFF",
          }}
        >
          ورود به پنل جدید
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={_onClose}
        activeOpacity={0.7}
        style={{ position: "absolute", top: 10, left: 10 }}
      >
        <Ionicons name="close" size={22} color="#FFFFFF99" />
      </TouchableOpacity>
    </View>
  )
}
