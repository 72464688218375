import { useCallback, useEffect, useReducer } from "react"
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  Platform,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import { useSelector } from "react-redux"
import Datepicker from "../../components/Datepicker"
import ProjectCard from "../../components/ProjectCard"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Config from "../../constants/Config"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_PROJECTS = "SET_PROJECTS"
const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION"
const SET_ACTIVE_STAFF = "SET_ACTIVE_STAFF"
const SET_STAFFS = "SET_STAFFS"
const TOGGLE_FILTERS = "TOGGLE_FILTERS"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_PROJECTS:
      return {
        ...state,
        values: {
          ...state.values,
          projects: action.data,
        },
      }
    case SET_STAFFS:
      return {
        ...state,
        values: {
          ...state.values,
          staffs: action.data,
        },
      }
    case SET_ACTIVE_SECTION:
      let department_staffs = []
      for (let i = 0; i < state.values.staffs.length; i++) {
        if (state.values.staffs[i].department == action.value) {
          department_staffs.push(state.values.staffs[i])
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          active_section: action.value,
          active_staff: null,
          department_staffs: department_staffs,
        },
      }
    case SET_ACTIVE_STAFF:
      return {
        ...state,
        values: {
          ...state.values,
          active_staff: action.value,
        },
      }
    case TOGGLE_FILTERS:
      return {
        ...state,
        values: {
          ...state.values,
          show_filters: state.values.show_filters ? false : true,
        },
      }
    default:
      return state
  }
}

const ProjectsArchiveScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      showAlert: false,
      loading: false,
      projects: [],
      active_section: null,
      active_staff: null,
      staffs: [],
      department_staffs: [],
      contract_date_from: null,
      contract_date_to: null,
      start_date_from: null,
      start_date_to: null,
      end_date_from: null,
      end_date_to: null,
      show_filters: true,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_projects = async () => {
    const url = Config.aioUrl + "/projects/getArchived"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("department", formState.values.active_section)

    //Filters
    if (formState.values.active_staff) {
      formdata.append("staff", formState.values.active_staff)
    }
    if (formState.values.contract_date_from) {
      formdata.append("contract_date_from", formState.values.contract_date_from)
    }
    if (formState.values.contract_date_to) {
      formdata.append("contract_date_to", formState.values.contract_date_to)
    }
    if (formState.values.start_date_from) {
      formdata.append("start_date_from", formState.values.start_date_from)
    }
    if (formState.values.start_date_to) {
      formdata.append("start_date_to", formState.values.start_date_to)
    }
    if (formState.values.end_date_from) {
      formdata.append("end_date_from", formState.values.end_date_from)
    }
    if (formState.values.end_date_to) {
      formdata.append("end_date_to", formState.values.end_date_to)
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        console.log(response.data)
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_PROJECTS, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  const get_staffs = async () => {
    const url = Config.aioUrl + "/staffs/get"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_STAFFS, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  useEffect(() => {
    get_staffs()
  }, [])
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="آرشیو پروژه ها" />
      {formState.values.show_filters && (
        <View style={{ zIndex: 2 }}>
          <View style={{ marginBottom: 20, alignItems: "flex-end" }}>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ flexDirection: "row-reverse" }}
            >
              <View style={{ flexDirection: "row-reverse", alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  دسته نمایش:{" "}
                </Text>
                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    borderRadius: 10,
                    backgroundColor:
                      formState.values.active_section == "site" ? Colors.blue : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => {
                    dispatchFormState({
                      type: SET_ACTIVE_SECTION,
                      value: "site",
                    })
                    dispatchFormState({ type: SET_ACTIVE_STAFF, value: "all" })
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: formState.values.active_section == "site" ? "#fff" : Colors.text,
                    }}
                  >
                    دپارتمان سایت
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    borderRadius: 10,
                    backgroundColor:
                      formState.values.active_section == "graphic" ? Colors.blue : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => {
                    dispatchFormState({
                      type: SET_ACTIVE_SECTION,
                      value: "graphic",
                    })
                    dispatchFormState({ type: SET_ACTIVE_STAFF, value: "all" })
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: formState.values.active_section == "graphic" ? "#fff" : Colors.text,
                    }}
                  >
                    دپارتمان گرافیک
                  </Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ flexDirection: "row-reverse", maxWidth: "100%" }}
            >
              <View
                style={{
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                {(formState.values.active_section == "site" ||
                  formState.values.active_section == "graphic") && (
                  <View
                    style={{
                      marginRight: 30,
                      flexDirection: "row-reverse",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Medium",
                        fontSize: 12,
                        color: Colors.text,
                      }}
                    >
                      پرسنل :{" "}
                    </Text>
                    <TouchableOpacity
                      style={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 10,
                        backgroundColor:
                          formState.values.active_staff == "all" ? Colors.blue : "#F2F2F2",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                      onPress={() => {
                        dispatchFormState({
                          type: SET_ACTIVE_STAFF,
                          value: "all",
                        })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: formState.values.active_staff == "all" ? "#fff" : Colors.text,
                        }}
                      >
                        همه
                      </Text>
                    </TouchableOpacity>
                    {formState.values.department_staffs.map((item, index) => (
                      <TouchableOpacity
                        key={item.id}
                        style={{
                          paddingVertical: 5,
                          paddingHorizontal: 10,
                          borderRadius: 10,
                          backgroundColor:
                            formState.values.active_staff == item.id ? Colors.blue : "#F2F2F2",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 5,
                        }}
                        onPress={() => {
                          dispatchFormState({
                            type: SET_ACTIVE_STAFF,
                            value: item.id,
                          })
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color: formState.values.active_staff == item.id ? "#fff" : Colors.text,
                          }}
                        >
                          {item.item}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                )}
              </View>
            </ScrollView>
          </View>
          <View style={{ flexDirection: "row-reverse", zIndex: 3 }}>
            <Datepicker
              label="قراداد از"
              blank="انتخاب"
              id="contract_date_from"
              onChange={inputChangeHandler}
              column={3}
              wrapperStyle={{ marginRight: "2%" }}
            />
            <Datepicker
              label="شروع از"
              blank="انتخاب"
              id="start_date_from"
              onChange={inputChangeHandler}
              column={3}
              wrapperStyle={{ marginLeft: "1%", marginRight: "1%" }}
            />
            <Datepicker
              label="پایان از"
              blank="انتخاب"
              id="end_date_from"
              onChange={inputChangeHandler}
              column={3}
              wrapperStyle={{ marginLeft: "2%" }}
            />
          </View>
          <View style={{ flexDirection: "row-reverse", zIndex: 2 }}>
            <Datepicker
              label="قراداد تا"
              blank="انتخاب"
              id="contract_date_to"
              onChange={inputChangeHandler}
              column={3}
              wrapperStyle={{ marginRight: "2%" }}
            />
            <Datepicker
              label="شروع تا"
              blank="انتخاب"
              id="start_date_to"
              onChange={inputChangeHandler}
              column={3}
              wrapperStyle={{ marginLeft: "1%", marginRight: "1%" }}
            />
            <Datepicker
              label="پایان تا"
              blank="انتخاب"
              id="end_date_to"
              onChange={inputChangeHandler}
              column={3}
              wrapperStyle={{ marginLeft: "2%" }}
            />
          </View>
        </View>
      )}
      <Btn
        title={formState.values.show_filters ? "جستجو" : "نمایش فیلترها"}
        onPress={async () => {
          if (formState.values.active_section) {
            await get_projects()
            dispatchFormState({ type: TOGGLE_FILTERS })
          } else {
            alert("دپارتمان را انتخاب کنید")
          }
        }}
      />

      {!formState.values.loading && (
        <View style={{ flex: 1 }}>
          <Text
            style={{
              color: Colors.text,
              fontFamily: "Iransans-Medium",
              fontSize: 14,
              marginBottom: 10,
              marginTop: 30,
            }}
          >
            {formState.values.projects.length} مورد یافت شد
          </Text>
          <FlatList
            data={formState.values.projects}
            keyExtractor={(item) => item.id}
            renderItem={(item) => (
              <ProjectCard
                item={item.item}
                onPress={
                  Platform.OS == "web"
                    ? { screen: "Project", params: { id: item.item.id } }
                    : () => {
                        props.navigation.navigate("Project", {
                          id: item.item.id,
                        })
                      }
                }
              />
            )}
          />
        </View>
      )}
      {formState.values.loading && (
        <ActivityIndicator size="large" color={Colors.primary} style={{ marginTop: 30 }} />
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default ProjectsArchiveScreen
